/*eslint-disable*/
// Credited-in -
// Credited-out -
// Write off = student due amount and disable amount input

import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  InputGroup,
  FormControl,
  Row,
  Col,
} from "react-bootstrap";
import Calendar from "react-calendar";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { payOffline } from "../../../store/actions";
import "react-calendar/dist/Calendar.css";
import moment from "moment";

function OfflinePay(props) {
  const { sponsorship } = props;
  const { beneficiary } = sponsorship;

  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [paymentReceivedData, setPaymentReceivedData] = useState(
    moment().format("MM-DD-YYYY")
  );
  const [dueDate, setDueDate] = useState(moment().format("MM-DD-YYYY"));
  const [showCalender, setShowCalender] = useState(false);
  const [showDueDateCalender, setShowDueDateCalender] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: { amount: 0, tip: 0 } });

  useEffect(() => {
    if (sponsorship?.dueDate) {
      setDueDate(moment(sponsorship?.dueDate).format("MM-DD-YYYY"));
    }
  }, [sponsorship]);
  const studentNeedMonthly = sponsorship?.amount;
  const note = watch("note");
  const enteredAmount = +watch("amount");
  const tip = +watch("tip");
  const type = watch("type");

  const tempAmount = tip ? (enteredAmount * (100 + tip)) / 100 : enteredAmount;
  const amount = type === "Credit-out" ? tempAmount * -1 : tempAmount;
  const monthlyTip = (studentNeedMonthly * tip) / 100;
  const numberOfMonths =
    beneficiary?.type === "elf"
      ? parseInt(enteredAmount)
      : parseInt(enteredAmount / studentNeedMonthly);
  const remainingAmount = enteredAmount % studentNeedMonthly;
  const spTotalTip = remainingAmount / numberOfMonths + monthlyTip;

  const formSubmit = (e) => {
    const payObj = {
      amount: amount,
      sponsorshipId: sponsorship.id,
      tip: spTotalTip.toFixed(2),
      month: numberOfMonths,
      note,
      type,
      paymentReceivedData,
    };

    if (type === "WriteOff" || type === "Credit-in" || type === "Credit-out") {
      payObj.dueDate = dueDate;
    }

    dispatch(payOffline(payObj, token));
  };
  const handleCalenderChange = (e) => {
    const date = moment(e).format("MM-DD-YYYY");
    setPaymentReceivedData(date);
    setShowCalender(false);
  };
  const handleCalenderDueDateChange = (e) => {
    const date = moment(e).format("MM-DD-YYYY");
    setDueDate(date);
    setShowDueDateCalender(false);
  };

  return (
    <div className="OfflinePay">
      <div className="formIn">
        <h4>Pay</h4>
        <Form onSubmit={handleSubmit(formSubmit)}>
          <Row>
            <Col>
              <InputGroup size="sm" className="ml-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>Pay By</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl as="select" {...register("type")}>
                  <option value="Cheque">Cheque</option>
                  <option value="Cash">Cash</option>
                  <option value="Credit-in" disabled>
                    Credit-in (+)
                  </option>
                  <option value="Credit-out" disabled>
                    Credit-out (-)
                  </option>
                  <option value="WriteOff" disabled>
                    Write off
                  </option>
                  <option value="Other">Other</option>
                </FormControl>
              </InputGroup>
            </Col>
            <Col>
              <InputGroup size="sm" className="ml-3">
                {errors.amount && errors.amount.message && (
                  <p className="errorMessage">{errors.amount.message}</p>
                )}
                {errors.amount && errors.amount.type === "isNumber" && (
                  <p className="errorMessage">Must be a valid number</p>
                )}
                <InputGroup.Prepend>
                  <InputGroup.Text>Amount</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  {...register("amount", {
                    validate: {
                      isNumber: (value) => !isNaN(+value),
                    },

                    required: {
                      value: true,
                      message: "Amount required.",
                    },
                  })}
                />
                <InputGroup.Append>
                  <InputGroup.Text>$</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            {(type === "Cash" || type === "Cheque") &&
              beneficiary.type !== "elf" && (
                <Col>
                  <InputGroup size="sm" className="ml-3">
                    {errors.tip && errors.tip.type === "positiveNumber" && (
                      <p className="errorMessage">Must be a valid number</p>
                    )}
                    {errors.tip && errors.tip.message && (
                      <p className="errorMessage">{errors.tip.message}</p>
                    )}
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        Add monthly tip percentage
                      </InputGroup.Text>
                    </InputGroup.Prepend>

                    <FormControl
                      {...register("tip", {
                        validate: {
                          positiveNumber: (value) => {
                            if (value) {
                              if (parseFloat(value) >= 0) {
                                return true;
                              } else {
                                return false;
                              }
                            } else {
                              return true;
                            }
                          },
                        },
                        max: {
                          value: 100,
                          message: "Amount should be less than 100",
                        },
                        required: false,
                      })}
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              )}

            <Col>
              <InputGroup size="sm" className="ml-3">
                <InputGroup.Prepend>
                  <InputGroup.Text>Date</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  readOnly
                  onClick={() => setShowCalender((prev) => !prev)}
                  value={paymentReceivedData}
                />
              </InputGroup>
              {showCalender && (
                <div className="calenderWrapper">
                  <Calendar onChange={handleCalenderChange} />
                </div>
              )}
            </Col>
            {type === "WriteOff" ||
            type === "Credit-in" ||
            type === "Credit-out" ? (
              <Col>
                <InputGroup size="sm" className="ml-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>Due Date</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    readOnly
                    onClick={() => setShowDueDateCalender((prev) => !prev)}
                    value={dueDate}
                  />
                </InputGroup>
                {showDueDateCalender && (
                  <div className="calenderWrapper">
                    <Calendar
                      value={new Date(dueDate)}
                      onChange={handleCalenderDueDateChange}
                    />
                  </div>
                )}
              </Col>
            ) : (
              ""
            )}
            <Col>
              <InputGroup size="sm" className="ml-3" width="100%">
                <InputGroup.Prepend>
                  <InputGroup.Text>Note</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl {...register("note")} />
              </InputGroup>
            </Col>
            <Button
              size="sm"
              variant="success"
              className="ml-3 mr-3"
              type="submit"
            >
              Pay Now
            </Button>
          </Row>
        </Form>
      </div>
      <div className="note">
        <strong>{beneficiary?.firstName}</strong> need{" "}
        <strong>{studentNeedMonthly}$</strong> per month.{" "}
        <strong>{studentNeedMonthly > 0 ? amount : 0}$</strong> can donate for{" "}
        <strong>{numberOfMonths > 0 ? numberOfMonths : 0} </strong>month. Total
        tip will be{" "}
        <strong>{spTotalTip > 0 ? spTotalTip.toFixed(2) : 0}$</strong> with
        extra.
      </div>
    </div>
  );
}

export default OfflinePay;
